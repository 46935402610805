import * as Yup from "yup";

export const loginSchema1 = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const cooperativeSignUpSchema = Yup.object().shape({
  cooperativeName: Yup.string().required("Name is required"),
  cooperativeRegNum: Yup.string().required("Registration number is required"),
  cooperativeEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  cooperativeSize: Yup.array().required("Cooperative size is required"),
});

export const memberSignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const confirmEmailSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const createNewPasswordSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
  password: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Password is required"),
  newPassword: Yup.string().required("Password is required"),
  confirmNewPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords do not match."),
});

export const CooperativeBankAccountSchema = Yup.object().shape({
  bank: Yup.object().required("Bank is required"),
  accountNumber: Yup.string("Account number is invali")
    .matches(/^[0-9]+$/, "Account number is invalid")
    .required("Account number is required")
    .min(10, "Account number must be exactly 10 digits")
    .max(10, "Account number must be exactly 10 digits"),
  accountName: Yup.string().required(""),
});

export const inviteCooperativeOfficialsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  role: Yup.object()
    .test("not-empty", "Role is required", (value) => {
      return Object.values(value)?.length > 0;
    })
    .required("Role is required"),
});

export const membershipFormSchema = Yup.object().shape({
  fee: Yup.string("Invalid fee amount").required("Fee is required"),
  form: Yup.array().of(Yup.object()).required("Form is required"),
});

export const addMembershipFormFieldSchema = Yup.object().shape({
  fieldName: Yup.string().required("Field name is required"),
  fieldType: Yup.object()
    .test("not-empty", "Field type is required", (value) => {
      return Object.values(value)?.length > 0;
    })
    .required("Field type is required"),
});

export const inviteMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const addSavingsSchema = Yup.object().shape({
  amount: Yup.string("Enter a valid amount").required("Amount is required"),
});

export const addSavingsSchemaStep2 = Yup.object().shape({
  amount: Yup.string("Enter a valid amount").required("Amount is required"),
  paymentMethod: Yup.string().required(),
});

export const addSavingsAdminSchema = Yup.object().shape({
  amount: Yup.string("Enter a valid amount").required("Amount is required"),
  file: Yup.array()
    .min(1, "Upload proof of payment")
    .required("Upload proof of payment"),
  date: Yup.date("Invalid date").required("Provide payment date"),
});

export const profileUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string().required("Middle name is required"),
  lastName: Yup.string().required("Last name is required"),
  dateOfBirth: Yup.string().required("Date of Birth is required"),
  dialCode: Yup.object()
    .required("Select an option from the list")
    .test("not-empty", "Select an option from the list", (value) => {
      return (
        Object.values(value)?.length > 0 &&
        Object.values(value)?.every((el) => el)
      );
    })
    .required("Select an option from the list"),
  phoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string().email().required("Email is required"),
  address: Yup.string().required("Address is required"),
  country: Yup.object().test(
    "not-empty",
    "Select an option from the list",
    (value) => {
      try {
        return (
          Object.values(value)?.length > 0 &&
          Object.values(value)?.every((el) => el)
        );
      } catch {
        return false;
      }
    }
  ),
  state: Yup.object().test(
    "not-empty",
    "Select an option from the list",
    (value) => {
      try {
        return (
          Object.values(value)?.length > 0 &&
          Object.values(value)?.every((el) => el)
        );
      } catch {
        return false;
      }
    }
  ),
});

export const depositConfigurationSchema = Yup.object().shape({
  withdrawalDays: Yup.number("Enter a valid value").required("Required"),
  depositsInterestRate: Yup.number("Enter a valid value").required("Required"),
});

export const sharesConfigurationSchema = Yup.object().shape({
  price: Yup.number("Enter a valid value").required("Required"),
  minAmount: Yup.number("Enter a valid value").required("Required"),
  sharesInterestRate: Yup.number("Enter a valid value").required("Required"),
});

export const addLoanProductSchema = Yup.object().shape({
  loanName: Yup.string().required("Loan name is required"),
  loanDescription: Yup.string().required("Loan description is required"),
  maxGuarantor: Yup.number("Enter a valid value").required("Required"),
  minMonthJoined: Yup.number("Enter a valid value").required("Required"),
  maxTenure: Yup.number("Enter a valid value").required("Required"),
});

export const extraLoanProductConfigurationSchema = Yup.object().shape({
  minLoanAmount: Yup.string("Enter a valid value").required(
    "Minimum loan amount is required"
  ),
  maxXAmount: Yup.string().required("Maximum loan amount is required"),
  maxInterestRate: Yup.number("Enter a valid value").required(
    "Interest rate is required"
  ),
  penaltyFee: Yup.number("Enter a valid value").required(
    "Penalty fee is required"
  ),
  loanApplicationFee: Yup.string("Enter a valid value").required(
    "Loan processing fee is required"
  ),
  multipleRunningLoan: Yup.boolean().required(),
  EnableDisbursement: Yup.boolean().required(),
});
